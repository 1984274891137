import React from 'react';
import { TopTopics } from '../../../../features/topics/components/TopTopics';

export const TopTopicsByTypeSection: React.FC<{
  filters: {
    from: string;
    to: string;
    accountId?: string;
    crmFilters?: Record<string, string[]>;
  };
  onTopicSelected: (topic: { id: string; name: string }) => unknown;
}> = ({ filters, onTopicSelected }) => {
  return (
    <>
      <TopTopics
        id="top-topics-risks"
        insightType="Risks"
        filters={filters}
        onTopicSelected={onTopicSelected}
      />

      <TopTopics
        id="top-topics-product-feedback"
        insightType="Product Feedback"
        filters={filters}
        onTopicSelected={onTopicSelected}
      />

      <TopTopics
        id="top-topics-advocacy"
        insightType="Advocacy"
        filters={filters}
        onTopicSelected={onTopicSelected}
      />

      <TopTopics
        id="top-topics-growth-opportunities"
        insightType="Growth Opportunities"
        filters={filters}
        onTopicSelected={onTopicSelected}
      />
    </>
  );
};
