import { v4 } from 'uuid';
import { PastEvent, PaginationMetadata } from '../../../API/types';
import { create } from 'zustand';
import { setAccumulatedItems } from '../utils';
import { ProviderIntegration } from '../../../redux/typings/store';

export const PAST_MEETINGS_PAGE_KEY = 'past-meetings';

const defaultDateRange: [null, null] = [null, null];
const defaultMetadata = {
  page: 1,
  itemsPerPage: 20,
  totalItems: 0,
  totalPages: 0,
};

export type PastMeetingStore = ReturnType<typeof createPastMeetingsStore>;

interface PastMeetingsState {
  events: PastEvent[];
  isFetching: boolean;
  updateAccounts: ProviderIntegration[];
  contacts: string[];
  dateRange: [Date, Date] | [null, null];
  dateRangeType: string;
  paginationMetadata: PaginationMetadata;
  page: number;
  pageSize: number;
  searchTerm: string;
  meetingType: string;
  mType: string | null;
  showMineOnly: boolean;
  showReadyOnly: boolean;
  canLoadData: boolean,
  contactsFiltered: (contacts: string[]) => unknown;
  eventsFetched: (events: PastEvent[]) => unknown;
  fetchStatusChanged: (isFetching: boolean) => unknown;
  updateAccountsFiltered: (accounts: ProviderIntegration[]) => unknown;
  dateRangeChanged: (range: [Date, Date] | [null, null]) => unknown;
  pageChanged: (value: number) => unknown;
  pageSizeChanged: (value: number) => unknown;
  paginationMetadataSet: (metadata: PaginationMetadata) => unknown;
  searchTermChanged: (value: string) => unknown;
  meetingTypeChanged: (value: string) => unknown;
  showMineOnlyChanged: (value: boolean) => unknown;
  showReadyOnlyChanged: (value: boolean) => unknown;
  onInsightChanged: (id: string, value: boolean) => unknown;
  resetFilters: () => unknown;
  keepFetching: () => unknown;
  refetch: () => unknown;
  reset: VoidFunction;
  deleteEvent: (id: string) => unknown;
  updateMeetingAccount: (
    id: string,
    account: { id: string; name: string; provider: string; mappingId: string } | null,
  ) => unknown;
  updateMType: (mType: string | null) => unknown;
  updateFiltersState: (filters: Record<string, any>) => void;

  //Internal
  fetchId: string;
}

const persistFilters = (newFilters: Record<string, any>) => {
  const currentFiltersObject = JSON.parse(localStorage.getItem('pageFilters') || '{}');
  const currentPageFilters = currentFiltersObject[PAST_MEETINGS_PAGE_KEY] || {};
  const newFiltersObject = {
    ...currentPageFilters,
    ...newFilters,
  };
  currentFiltersObject[PAST_MEETINGS_PAGE_KEY] = newFiltersObject;
  localStorage.setItem('pageFilters', JSON.stringify(currentFiltersObject));
}

export const createPastMeetingsStore = () => {
  return create<PastMeetingsState>(set => {
    return {
      events: [],
      isFetching: false,
      updateAccounts: [],
      contacts: [],
      dateRange: defaultDateRange,
      dateRangeType: '30d',
      canLoadData: false,
      paginationMetadata: defaultMetadata,
      page: 1,
      pageSize: 20,
      searchTerm: '',
      meetingType: 'all',
      mType: null,
      showMineOnly: false,
      showReadyOnly: false,

      // Filters
      updateFiltersState: filters => set(filters),
      contactsFiltered: contacts => set({ contacts, page: 1, events: [] }),
      updateAccountsFiltered: accounts =>
        set(store => {
          if (store.updateAccounts.length !== accounts.length) {
            persistFilters({ updateAccounts: accounts });
            return { updateAccounts: accounts, page: 1, events: [] };
          } else {
            const set = new Set(accounts.map(account => account.id));
            for (const acc of store.updateAccounts) {
              if (set.has(acc.id)) {
                set.delete(acc.id);
              } else {
                set.add(acc.id);
              }
            }
            if (set.size !== 0) {
              persistFilters({ updateAccounts: accounts });
              return { updateAccounts: accounts, page: 1, events: [] }
            };
            return store;
          }
        }),
      dateRangeChanged: (range: [Date, Date] | [null, null]) => {
        set({ dateRange: range, page: 1, events: [] })
        persistFilters({ dateRange: range });
      },
      searchTermChanged: (value: string) => set({ searchTerm: value, page: 1, events: [] }),
      meetingTypeChanged: (value: string) => {
        set({ meetingType: value, page: 1, events: [] })
        persistFilters({ meetingType: value });
      },
      showMineOnlyChanged: (value: boolean) => {
        set({ showMineOnly: value, page: 1, events: [] })
        persistFilters({ showMineOnly: value });
      },
      showReadyOnlyChanged: (value: boolean) => {
        set({ showReadyOnly: value, page: 1, events: [] })
        persistFilters({ showReadyOnly: value });
      },
      resetFilters: () => {
        set({
          events: [],
          contacts: [],
          updateAccounts: [],
          showMineOnly: false,
          showReadyOnly: false,
          mType: null,
          meetingType: 'all',
          paginationMetadata: defaultMetadata,
          page: 1,
        })
        const currentFiltersObject = JSON.parse(localStorage.getItem('pageFilters') || '{}');
        delete currentFiltersObject[PAST_MEETINGS_PAGE_KEY];
        localStorage.setItem('pageFilters', JSON.stringify(currentFiltersObject));
      },

      pageChanged: (value: number) => set({ page: value }),
      pageSizeChanged: (value: number) => set({ pageSize: value }),
      paginationMetadataSet: metadata => set({ paginationMetadata: metadata }),

      keepFetching: () => {
        set(state => {
          if (state.paginationMetadata.page < state.paginationMetadata.totalPages) {
            return { page: state.page + 1 };
          }
          return state;
        });
      },
      eventsFetched: events => {
        set(state => {
          return { events: setAccumulatedItems(state.events, events) };
        });
      },
      refetch: () => {
        set({
          paginationMetadata: defaultMetadata,
          page: 1,
          fetchId: v4(),
          events: [],
        });
      },
      reset: () => {
        set({
          searchTerm: '',
          showMineOnly: false,
          showReadyOnly: false,
          meetingType: 'all',
          mType: null,
          updateAccounts: [],
          dateRangeType: '30d',
          dateRange: [null, null],
        })
        const currentFilters = JSON.parse(localStorage.getItem('pageFilters') || '{}');
        delete currentFilters[PAST_MEETINGS_PAGE_KEY];
        localStorage.setItem('pageFilters', JSON.stringify(currentFilters));
      },
      fetchStatusChanged: isFetching => set({ isFetching }),
      deleteEvent: id => {
        set(state => {
          return { events: state.events.filter(e => e.id !== id) };
        });
      },
      updateMeetingAccount: (id, account) =>
        set(state => {
          const event = state.events.find(event => event.id === id);
          if (event) event.account = account;
          return { events: state.events };
        }),
      onInsightChanged: (id: string, val: boolean) => {
        set(state => {
          const event = state.events.find(event => event.id === id);
          if (event) event.excludedFromInsights = val;
          return { events: state.events };
        });
      },
      updateMType: mType => {
        set({
          mType,
          page: 1,
          events: [],
        })
        persistFilters({ mType });
      },

      // Internal
      fetchId: v4(),
    };
  });
};
