import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TitledVerticalFlexLayout } from '../../features/shared/components/VerticalFixedLayout/VerticalFixedLayout';
import { useLocationChange } from '../../features/analytics/hooks/useLocationChange';
import { Button, Flex, Menu, Space } from 'antd';
import { ApiOutlined } from '@ant-design/icons';
import { UpgradeToPro } from '../../componentsV2/UpgradeToPro';
import { useDashboardFilters } from '../../features/shared/dashboard-filters/dashboard-filters.hooks';
import { TopTopicsByTypeSection } from './components/TopTopicByType';
import { AllInsightQueryData } from '../../services/types';
import { InsightsAskMeAnything } from '../insights/components/InsightsAskMeAnything';
import { KeyToFilterStore } from '../../features/shared/dashboard-filters/types';
import StoredFilters from '../insights/components/StoredFilters';
import useFeatures from '../../hooks/useFeatures';
import Styles from './styles';
import { TopAccounts } from '../../features/topics/components/TopAccounts';
import { addDays } from 'date-fns';
import { TopTopics } from '../../features/topics/components/TopTopics';
import { TopicDetailModal } from '../../features/topics/components/TopicDetailModal';
import { useCrmFilterValues } from '../../features/crm-integration/hooks/use-crm-filter-values';
import { useSearchParams } from 'react-router-dom';

const { Contents, FiltersGroup, Header, HeaderRow, TopTopicsScrollableContainer } = Styles;

interface Props {
  initialData: AllInsightQueryData | null;
  isCRMConnected: boolean;
  onStoreFilter(f: Partial<KeyToFilterStore>): void;
}

const sections = [
  { id: 'top-topics-risks', label: 'Top Risks' },
  { id: 'top-topics-product-feedback', label: 'Top Product Feedback' },
  { id: 'top-topics-advocacy', label: 'Top Advocacy' },
  { id: 'top-topics-growth-opportunities', label: 'Top Growth Opportunities' },
  { id: 'top-topics-topics', label: ' Top Topics' },
  { id: 'top-topics-accounts', label: ' Top Accounts' },
];

export const PortfolioInsightsPage: React.FC<Props> = ({ initialData, isCRMConnected }) => {
  useLocationChange();

  const [params, setParams] = useSearchParams();

  const { filterKeys: crmFiltersKeys } = useCrmFilterValues();

  const [availableContainerHeight, setAvailableContainerHeight] = useState<number>(0);
  const [activeSection, setActiveSection] = useState<string>(sections[0].id);
  const containerRef = useRef<HTMLDivElement>(null);

  const [showFilters, setShowFilters] = useState(true);
  const [selectedTopic, setSelectedTopic] = useState<{
    topic: { id: string; name: string };
    accountId: string;
  }>({
    topic: {
      id: '',
      name: '',
    },
    accountId: '',
  });
  const {
    render,
    filters,
    getDateFilterOptions,
    getAccountFilterOptions,
    changeAccountValue,
    getListFilterOptions,
  } = useDashboardFilters();

  const features = useFeatures();
  const availableFeature = features.portfolioInsights;

  const handleConnectCrm = () => {
    window.open('/settings?tab=integrations', '_blank');
  };

  const topAccountsFilters = useMemo(() => {
    const dateValues = getDateFilterOptions('dateRange');
    const accountValue = getAccountFilterOptions('account');
    let crmFilters: Record<string, string[]> | undefined = undefined;

    if (crmFiltersKeys.length) {
      crmFiltersKeys.forEach(crmFilterKey => {
        const selectedValues = getListFilterOptions(`crm_${crmFilterKey}`)
          .filter(s => s.selected)
          .map(s => s.id);
        if (selectedValues.length) {
          if (!crmFilters) crmFilters = {};
          crmFilters[crmFilterKey] = selectedValues;
        }
      });
    }
    return {
      from: dateValues[0] ? dateValues[0].toISOString() : addDays(new Date(), -30).toISOString(),
      to: dateValues[1] ? dateValues[1].toISOString() : new Date().toISOString(),
      accountId: accountValue || '',
      crmFilters,
    };
  }, [filters, crmFiltersKeys]);

  const topicDetailsModalFilters = useMemo(() => {
    if (selectedTopic.accountId) {
      return {
        accountId: selectedTopic.accountId,
        from: topAccountsFilters.from,
        to: topAccountsFilters.to,
        crmFilters: topAccountsFilters.crmFilters,
      };
    }

    return topAccountsFilters;
  }, [topAccountsFilters, selectedTopic]);

  useEffect(() => {
    if (!params.has('section')) {
      return;
    }

    const section = `top-topics-${params.get('section')}` || sections[0].id;
    if (section !== activeSection) {
      setActiveSection(section || sections[0].id);
      scrollToSection(section || sections[0].id);
    }
  }, [params]);

  const calculateContainerHeight = () => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const pointX = rect.top;
      const heightFromX = window.innerHeight - pointX;
      setAvailableContainerHeight(heightFromX - 24); // 24 is for not hitting the end of the page
    }
  };

  const handleContainerScroll = () => {
    if (!containerRef.current) return;

    for (const section of sections) {
      const element = document.getElementById(section.id);
      if (element) {
        const rect = element.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();
        const relativeTop = rect.top - containerRect.top;

        if (relativeTop >= 0 && relativeTop < containerRef.current.offsetHeight / 2) {
          setActiveSection(section.id);
          break;
        }
      }
    }
  };

  useEffect(() => {
    calculateContainerHeight();

    if (containerRef.current) {
      window.removeEventListener('resize', calculateContainerHeight);
      containerRef.current.addEventListener('scroll', handleContainerScroll);
    }

    return () => {
      if (containerRef.current) {
        window.removeEventListener('resize', calculateContainerHeight);
        containerRef.current.removeEventListener('scroll', handleContainerScroll);
      }
    };
  }, []);

  const scrollToSection = (id: string) => {
    const container = containerRef.current;
    const element = document.getElementById(id);

    if (container && element) {
      const containerTop = container.getBoundingClientRect().top;
      const elementTop = element.getBoundingClientRect().top;
      const offset = elementTop - containerTop;

      container.scrollTo({
        top: container.scrollTop + offset,
        behavior: 'smooth',
      });
    }
  };

  const handleMenuItemClick = (key: string) => {
    scrollToSection(key);
    const newValue = key.replace('top-topics-', '');
    params.set('section', newValue);
    setParams(params);
  };

  return (
    <>
      <TitledVerticalFlexLayout
        sider={true}
        title={
          <Flex gap={'10px'} align="center">
            VoC Topic Discovery
          </Flex>
        }
        titleDescription={
          <Flex align="center" justify="space-between">
            <div style={{ maxWidth: '1000px' }}>
              UpdateAI uncovers themes from your workspace's external customer meetings. No setup is
              needed, but themes require 300 meetings to take shape. For privacy, you can only see
              themes from the meetings you have access to.
            </div>

            <StoredFilters
              showHideFilter={() => setShowFilters(!showFilters)}
              disabled={!availableFeature}
              label={(showFilters ? 'Hide' : 'Show') + ' Filters'}
            />
          </Flex>
        }
      >
        <UpgradeToPro plan="business" display={!availableFeature}>
          <Contents>
            <Header>
              <HeaderRow visible={showFilters}>
                <FiltersGroup>
                  {render('dateRange')}
                  {render('account')}
                  {render('industry')}
                  {render('lifecycleStage')}
                  {render('arr')}
                  {render('geography')}
                  {render('revenueSegment')}
                  {render('dealStage')}
                  {render('daysUntilRenewal')}
                  {render('meetingType')}
                  {crmFiltersKeys.map(filter => render(`crm_${filter}`))}

                  {!isCRMConnected && (
                    <Button type="link" onClick={handleConnectCrm}>
                      <Space>
                        Connect your CRM
                        <ApiOutlined />
                      </Space>
                    </Button>
                  )}

                  <StoredFilters.Actions />
                </FiltersGroup>
              </HeaderRow>
            </Header>
            <Flex align="center" justify="space-between">
              <InsightsAskMeAnything category="Risks" />
            </Flex>

            <div>
              <Menu
                style={{
                  borderRadius: '6px',
                  border: '1px solid #dfe2e6',
                  boxShadow: '0px 20px 24px rgba(24,30,36,0.06)',
                }}
                mode="horizontal"
                selectedKeys={[activeSection]}
                onClick={e => handleMenuItemClick(e.key)}
                items={sections.map(item => ({
                  label: item.label,
                  key: item.id,
                }))}
              />

              <TopTopicsScrollableContainer
                ref={containerRef}
                style={{ maxHeight: `${availableContainerHeight}px` }}
              >
                <TopTopicsByTypeSection
                  filters={topAccountsFilters}
                  onTopicSelected={topic => setSelectedTopic({ topic, accountId: '' })}
                />

                <TopTopics
                  id="top-topics-topics"
                  filters={topAccountsFilters}
                  onTopicSelected={topic => setSelectedTopic({ topic, accountId: '' })}
                />

                <TopAccounts
                  onAccountClicked={accountId => changeAccountValue('account', accountId)}
                  filters={topAccountsFilters}
                  onTopicClicked={(topic, accountId) => setSelectedTopic({ topic, accountId })}
                />
              </TopTopicsScrollableContainer>
            </div>

            <TopicDetailModal
              filters={topicDetailsModalFilters}
              open={!!selectedTopic.topic.id}
              topic={selectedTopic.topic}
              onClose={() => setSelectedTopic({ topic: { id: '', name: '' }, accountId: '' })}
            />
          </Contents>
        </UpgradeToPro>
      </TitledVerticalFlexLayout>
    </>
  );
};
