import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useCrmFilterValues } from '../../../../features/crm-integration/hooks/use-crm-filter-values';
import { useCrmIntegration } from '../../../../features/crm-integration/hooks/use-crm-integration';
import { useCustomerIntelligenceFilters } from '../../../../features/insights/hooks/useCustomerIntelligenceFilters';
import { SelectAll } from '../SelectAll';

export const CRMFilters: React.FC = () => {
  const { filters: crmFilters } = useCrmFilterValues();
  const { workspaceCrmIntegration } = useCrmIntegration();
  const { setCrmMap } = useCustomerIntelligenceFilters();

  const [values, setValues] = useState<Map<string, string[]>>(new Map());

  const components = useMemo(() => {
    const newComps: ReactElement[] = [];

    for (const filt in crmFilters) {
      const label = workspaceCrmIntegration
        ? workspaceCrmIntegration.workspace.properties.find(p => p.id === filt)?.label || filt
        : filt;

      const options = crmFilters[filt].map(opt => ({
        id: opt,
        label: opt,
      }));

      newComps.push(
        <SelectAll
          key={filt}
          label={label}
          options={options}
          value={values.get(filt) || []}
          showAll={true}
          style={{ width: '220px', maxHeight: '32px' }}
          onSelectionChanged={selectedValues => {
            setValues(prevValue => new Map(prevValue).set(filt, selectedValues));
          }}
        />,
      );
    }

    return newComps;
  }, [workspaceCrmIntegration, crmFilters, values]);

  useEffect(() => setCrmMap(values), [values]);

  return <>{components}</>;
};
