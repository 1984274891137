import React, { useEffect, useState } from 'react';
import { useUploadRecording } from '../../upload-recording.hook';
import { Modal } from '../../../../../componentsV2/Modal';
import Styles from './styles';
import { DatePicker, Input, Progress, Select, Space, TimePicker, Upload } from 'antd';
import { FileOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Alert } from '../../../../../components/alerts/Alert';
import { RcFile } from 'antd/es/upload';
import { coreService } from '../../../../../services/core/core-service';
import { extractError } from '../../../../../utils/api';
import { useToast } from '../../../../../hooks/useToast';
import { secondsToTime } from '../../../../../utils/dateUtils';
import dayjs, { Dayjs } from 'dayjs';

const { Dragger } = Upload;
const { Container, FormItem, Label, DoubleColumnContainer } = Styles;

export const UploadRecordingModal: React.FC = () => {
  const { isOpen, close } = useUploadRecording();
  const [selectedFile, setSelectedFile] = useState<{
    file: string | Blob | RcFile | null;
    fileName: string;
  }>({
    file: null,
    fileName: '',
  });
  const [isUploading, setIsUploading] = useState(false);
  const [title, setTitle] = useState<string | undefined>();
  const [participantsType, setParticipantsType] = useState<string>('');
  const [duration, setDuration] = useState<string>('');
  const { success, error } = useToast();
  const [progress, setProgress] = useState(0);
  const [meetingDate, setMeetingDate] = useState<Dayjs>();
  const [meetingTime, setMeetingTime] = useState<{
    startTime: Dayjs | null;
    endTime: Dayjs | null;
  }>({
    startTime: null,
    endTime: null,
  });

  useEffect(() => {
    if (meetingTime.startTime && !meetingTime.endTime) {
      calculateMeetingEndTime(meetingTime.startTime);
    }
  }, [duration]);

  const handleExit = () => {
    setSelectedFile({
      file: null,
      fileName: '',
    });
    setIsUploading(false);
    setTitle('');
    setParticipantsType('');
    setDuration('');
    setMeetingDate(undefined);
    setMeetingTime({
      startTime: null,
      endTime: null,
    });
    setProgress(0);
    close();
  };

  function mergeDateAndTime(date: string, time: string): string {
    const combined = `${date}T${time}`;
    return combined;
  }

  const dateFactory = () => {
    return {
      scheduledStartDate: mergeDateAndTime(
        meetingDate!.format('YYYY-MM-DD'),
        meetingTime.startTime!.format('HH:mm:ss'),
      ),
      scheduledEndDate: mergeDateAndTime(
        meetingDate!.format('YYYY-MM-DD'),
        meetingTime.endTime!.format('HH:mm:ss'),
      ),
    };
  };

  const handleUpload = async () => {
    setIsUploading(true);
    await coreService
      .uploadRecording(
        {
          file: selectedFile.file,
          title,
          participantsType,
          ...dateFactory(),
        },
        progressUpdate => setProgress(progressUpdate),
      )
      .then(() => {
        success('Meeting recording uploaded successfully');
        handleExit();
      })
      .catch(err => {
        const message = extractError(err);
        error(message);
      })
      .finally(() => setIsUploading(false));
  };

  const handleCancel = () => {
    handleExit();
  };

  const areArgsValid =
    Boolean(participantsType) &&
    parseInt(duration) > 0 &&
    Boolean(selectedFile.file) &&
    !!meetingDate &&
    !!meetingTime.startTime;

  const roundToNextMinute = (seconds: number): number => {
    return Math.ceil(seconds / 60) * 60;
  };

  const calculateMeetingEndTime = (e: Dayjs) => {
    setMeetingTime({
      startTime: e,
      endTime: duration ? e.add(roundToNextMinute(parseInt(duration)), 'second') : null,
    });
  };

  return (
    <Modal
      width={500}
      primaryAction={{
        label: 'Upload Recording',
        onClick: handleUpload,
        disabled: isUploading || !areArgsValid,
      }}
      secondaryAction={{ label: 'Cancel', onClick: handleCancel }}
      title="Upload a Meeting Recording"
      open={isOpen}
      onClose={handleExit}
    >
      <Container>
        <Alert
          variant="info"
          title=""
          message="You can upload a single video (.mp4) or audio file (.m4a). Max file size allowed is 1 GB."
        />
        <Dragger
          name="file"
          showUploadList={false}
          multiple={false}
          accept="video/mp4,audio/x-m4a"
          customRequest={options => {
            const { file, filename } = options;
            const { name } = file as any;

            if (typeof file === 'string') {
              return;
            }

            const video = document.createElement('video');
            video.preload = 'metadata';

            video.onloadedmetadata = function () {
              window.URL.revokeObjectURL(video.src);
              const duration = Math.ceil(video.duration);
              setDuration(duration.toString());
            };

            video.src = URL.createObjectURL(file);
            setSelectedFile({
              file,
              fileName: name || '',
            });
          }}
        >
          {' '}
          <p className="ant-upload-drag-icon">
            <FileOutlined />
          </p>
          {isUploading && (
            <div style={{ padding: '24px' }}>
              <Progress percent={progress} />
            </div>
          )}
          {!isUploading && (
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
          )}
        </Dragger>
        {selectedFile.file && (
          <Space>
            <PlayCircleOutlined />
            {selectedFile.fileName}
          </Space>
        )}
        <FormItem>
          <Label>Meeting Title (Recommended)</Label>
          <Input value={title} onChange={ev => setTitle(ev.target.value)} />
        </FormItem>
        <FormItem>
          <Label>Participants Type</Label>
          <Select
            value={participantsType}
            onChange={value => setParticipantsType(value)}
            options={[
              {
                label: 'Internal',
                value: 'internal',
              },
              { label: 'External', value: 'external' },
            ]}
          />
        </FormItem>
        {duration && (
          <FormItem>
            <Label>Duration</Label>
            <Label>{secondsToTime(duration)}</Label>
          </FormItem>
        )}
        <FormItem>
          <Label>Meeting Date</Label>
          <DatePicker
            format={'MM/DD/YYYY'}
            maxDate={dayjs()}
            onCalendarChange={date => {
              setMeetingDate(date as Dayjs);
            }}
          />
        </FormItem>

        <DoubleColumnContainer>
          <FormItem>
            <Label>Start Time</Label>
            <TimePicker
              format="HH:mm a"
              use12Hours
              showNow={false}
              onChange={e => {
                if (!e && meetingTime.endTime) {
                  setMeetingTime({
                    startTime: null,
                    endTime: null,
                  });
                }
              }}
              value={meetingTime.startTime}
              onPickerValueChange={e => {
                calculateMeetingEndTime(e);
              }}
            />
          </FormItem>

          <FormItem>
            <Label>End Time</Label>
            <TimePicker format="HH:mm a" use12Hours disabled value={meetingTime.endTime} />
          </FormItem>
        </DoubleColumnContainer>
      </Container>
    </Modal>
  );
};
