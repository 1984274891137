import React, { ReactNode, useState } from 'react';
import Account from './Account';
import {
  Branding,
  Content,
  Divider,
  Footer,
  Header,
  ItemContainer,
  Nav,
  NavItem,
  NavItemText,
  Navigation,
  Organization,
  OrganizationControl,
  OrganizationDetails,
  OrganizationInfo,
  OrganizationLogo,
  OrganizationName,
  Plan,
  useSafariViewportHeight,
  LearningCenter,
} from './style';
import IconSvg from '../../components/common/IconSvg';
import {
  BarChartOutlined,
  CalendarOutlined,
  CloudUploadOutlined,
  LineChartOutlined,
  LoadingOutlined,
  MessageOutlined,
  PlayCircleOutlined,
  PlusSquareOutlined,
  SettingOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { Menu, MenuProps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser, selectSidebarOption, toggleSidebar } from '../../redux/slices/sessionSlice';
import { useOrganizationMembers } from '../../features/organizations/hooks/useOrganizationMembers';
import { useOrganizationDetails } from '../../features/organizations/hooks/useOrganizationDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { useLearningCenter } from '../../features/help/learning-center/learning-center.hook';
import { selectUser } from '../../redux/selectors';
import { SubscribeToProPlanLink, UpgradeToProBadge } from '../UpgradeToPro';
import { useInviteBot } from '../../features/bot/invite-bot/invite-bot.hook';
import { OptionalTooltipWrapper } from '../OptionalTooltipWrapper';
import { useUploadRecording } from '../../features/recordings/upload-recording/upload-recording.hook';
import useFeatures from '../../hooks/useFeatures';

interface Props {
  collapsed?: boolean;
  selectedKey?: string;
  setCollapsed: (collapsed: boolean) => void;
}

type MenuItem = Required<MenuProps>['items'][number];

export const SideBar: React.FC<Props> = ({ collapsed = false, selectedKey, setCollapsed }) => {
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const features = useFeatures();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { open } = useLearningCenter();
  const { show } = useInviteBot();
  const { show: showUploadRecordingModal } = useUploadRecording();

  // Add Safari viewport height handler
  useSafariViewportHeight();

  const availableFeature = features.uploadRecordings;
  const user = useSelector(selectUser);
  const { name, isFetching } = useOrganizationDetails();
  const { membersCount } = useOrganizationMembers();

  const logout = () => {
    dispatch(logoutUser());
    navigate('/');
  };

  const goToMyOrganization = () => {
    navigate('/settings?tab=my-organization');
  };

  const toNavItemComponent = (
    onClick: () => void,
    title: string | ReactNode,
    icon: JSX.Element,
  ) => (
    <NavItem onClick={onClick}>
      <ItemContainer>
        {icon}
        {!collapsed && <NavItemText>{title}</NavItemText>}
      </ItemContainer>
    </NavItem>
  );

  const handleChildClick = (navigateFn: () => void) => {
    navigateFn();
    setCollapsed(true);
  };

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (latestOpenKey) {
      setOpenKeys([latestOpenKey]);
    } else {
      setOpenKeys([]);
    }
  };

  const items: MenuItem[] = [
    {
      key: 'meeting_insights',
      label: toNavItemComponent(
        () => null,
        'Meeting Insights',
        <CalendarOutlined style={{ fontSize: '24px', display: 'flex', verticalAlign: 'middle' }} />,
      ),
      children: [
        {
          key: 'past_meetings',
          label: toNavItemComponent(
            () => handleChildClick(() => navigate('/meetings/recaps')),
            'Past Meetings',
            <span />,
          ),
        },
        {
          key: 'upcoming_meetings',
          label: toNavItemComponent(
            () => handleChildClick(() => navigate('/meetings/upcoming')),
            'Upcoming Meetings',
            <span />,
          ),
        },
        {
          key: 'invite_bot',
          label: toNavItemComponent(
            () => handleChildClick(() => show()),
            'Invite Bot to Meeting',
            <span />,
          ),
        },
        {
          key: 'upload_meeting',
          disabled: !availableFeature,
          label: toNavItemComponent(
            () => handleChildClick(() => showUploadRecordingModal()),
            <>Upload New Meeting {!availableFeature && <UpgradeToProBadge />}</>,
            <span />,
          ),
        },
      ],
    },
    {
      key: 'account_insights',
      label: toNavItemComponent(
        () => null,
        'Account Insights',
        <LineChartOutlined
          style={{ fontSize: '24px', display: 'flex', verticalAlign: 'middle' }}
        />,
      ),
      children: [
        {
          key: 'account_briefs',
          label: toNavItemComponent(
            () => handleChildClick(() => navigate('/accounts')),
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              Account Briefs{!user?.features.accountInsights && <UpgradeToProBadge />}
            </div>,
            <span />,
          ),
        },
      ],
    },
    {
      key: 'portfolio_insights',
      label: toNavItemComponent(
        () => null,
        'Portfolio Insights',
        <BarChartOutlined style={{ fontSize: '24px', display: 'flex', verticalAlign: 'middle' }} />,
      ),
      children: [
        {
          key: 'topics_dashboard',
          label: toNavItemComponent(
            () => handleChildClick(() => navigate('/portfolio-insights')),
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              VoC Topic Discovery{!user?.features.portfolioInsights && <UpgradeToProBadge />}
            </div>,
            <span />,
          ),
        },
        {
          key: 'trackers_dashboard',
          label: toNavItemComponent(
            () => handleChildClick(() => navigate('/insights')),
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              VoC Topic Tracking{!user?.features.portfolioInsights && <UpgradeToProBadge />}
            </div>,
            <span />,
          ),
        },
      ],
    },
    {
      key: 'media_library',
      label: toNavItemComponent(
        () => null,
        'Media Library',
        <PlayCircleOutlined
          style={{ fontSize: '24px', display: 'flex', verticalAlign: 'middle' }}
        />,
      ),
      children: [
        {
          key: 'my_library',
          label: toNavItemComponent(
            () => handleChildClick(() => navigate('/libraries?tab=my-library')),
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              My Library{!user?.features.favoritesFolder && <UpgradeToProBadge />}
            </div>,
            <span />,
          ),
        },
        {
          key: 'workspace_library',
          label: toNavItemComponent(
            () => handleChildClick(() => navigate('/libraries?tab=workspace')),
            'Workspace Library',
            <span />,
          ),
        },
      ],
    },
    {
      key: 'settings',
      label: toNavItemComponent(
        () => null,
        'Settings',
        <SettingOutlined style={{ fontSize: '24px', display: 'flex', verticalAlign: 'middle' }} />,
      ),
      children: [
        {
          key: 'my_account',
          label: toNavItemComponent(
            () => handleChildClick(() => navigate('/settings?tab=my-account')),
            'My Account',
            <span />,
          ),
        },
        {
          key: 'my_workspace',
          label: toNavItemComponent(
            () => handleChildClick(() => navigate('/settings?tab=my-workspace')),
            'My Workspace',
            <span />,
          ),
        },
        {
          key: 'recording_settings',
          label: toNavItemComponent(
            () => handleChildClick(() => navigate('/settings?tab=recording')),
            'Recording Settings',
            <span />,
          ),
        },
        {
          key: 'insight_frameworks',
          label: toNavItemComponent(
            () => handleChildClick(() => navigate('/settings?tab=frameworks')),
            'Insight Frameworks Settings',
            <span />,
          ),
        },
        {
          key: 'notifications',
          label: toNavItemComponent(
            () => handleChildClick(() => navigate('/settings?tab=notifications')),
            'Notifications',
            <span />,
          ),
        },
        {
          key: 'integrations',
          label: toNavItemComponent(
            () => handleChildClick(() => navigate('/settings?tab=integrations')),
            'Integrations',
            <span />,
          ),
        },
      ],
    },
  ];

  const organizationInitial = name.length > 1 ? name.charAt(0) : '';

  return (
    <Content
      onMouseEnter={() => setCollapsed(false)}
      onMouseLeave={() => setCollapsed(true)}
      style={{
        width: collapsed ? '90px' : '280px',
        WebkitOverflowScrolling: 'touch',
      }}
    >
      <Nav>
        <Header onClick={() => dispatch(toggleSidebar())}>
          <Branding isCollapsed={collapsed}>
            {!collapsed ? (
              <IconSvg
                name="updateAiLogoStretched"
                size="lg"
                color="transparent"
                width="130px"
                height="32px"
                clickable
              />
            ) : (
              <IconSvg
                name="updateFull"
                size="lg"
                color="transparent"
                width="28px"
                height="32px"
                clickable
              />
            )}
          </Branding>
        </Header>

        <Organization isCollapsed={collapsed}>
          {!isFetching ? (
            <OrganizationControl onClick={goToMyOrganization} show={!collapsed}>
              <OrganizationLogo>{organizationInitial}</OrganizationLogo>
              {!collapsed && (
                <OrganizationInfo>
                  <OrganizationName>{name ?? '-'}</OrganizationName>
                  <OrganizationDetails>
                    <Plan>{`${membersCount} Member${membersCount > 1 ? 's' : ''}`}</Plan>
                  </OrganizationDetails>
                </OrganizationInfo>
              )}
            </OrganizationControl>
          ) : (
            <div style={{ margin: '0 auto' }}>
              <LoadingOutlined style={{ fontSize: 16 }} spin />
            </div>
          )}
        </Organization>

        <Navigation>
          <Menu
            style={{
              width: '100%',
              border: 'none',
              WebkitTapHighlightColor: 'transparent',
            }}
            className="sidebar-menu"
            selectedKeys={[selectedKey || '']}
            defaultOpenKeys={[]}
            openKeys={!collapsed ? openKeys : []}
            onOpenChange={!collapsed ? onOpenChange : undefined}
            onSelect={e => {
              dispatch(selectSidebarOption({ selectedSideBarOption: e.key }));
            }}
            mode="inline"
            inlineCollapsed={collapsed}
            theme="light"
            items={items}
          />
        </Navigation>
      </Nav>

      <Footer style={{ marginTop: 'auto' }}>
        <LearningCenter>
          <div>
            <IconSvg name="NewIcnSVG" size="mdl" color="#9EA9B1" hoverColor="#e6614c" />
          </div>
          {!collapsed && (
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}
              onClick={() => window.open('https://product.update.ai/', '_blank')}
            >
              <span
                style={{
                  fontFamily: 'Cera Pro',
                  fontSize: '14px',
                  fontWeight: 950,
                  color: selectedKey === 'learning_center' ? '#ff6c54' : '#282828',
                }}
              >
                Feature Release Notes
              </span>
              <span
                style={{
                  fontFamily: 'Cera Pro',
                  fontSize: '12px',
                  fontWeight: 500,
                  color: selectedKey === 'learning_center' ? '#ff6c54' : '#282828',
                }}
              >
                Updated on January 30, 2025
              </span>
            </div>
          )}
        </LearningCenter>
        <LearningCenter
          onClick={() => handleChildClick(() => open())}
          className={selectedKey === 'learning_center' ? 'active' : ''}
        >
          <FontAwesomeIcon
            icon={faGraduationCap}
            style={{
              width: '24px',
              height: '24px',
              color: selectedKey === 'learning_center' ? '#ff6c54' : '#a6a6a6',
            }}
          />
          {!collapsed && (
            <span
              style={{
                fontFamily: 'Cera Pro',
                fontSize: '14px',
                fontWeight: 950,
                color: selectedKey === 'learning_center' ? '#ff6c54' : '#282828',
              }}
            >
              Learning Center
            </span>
          )}
        </LearningCenter>
        <Divider />
        {user ? (
          <Account
            firstName={user.firstName}
            lastName={user.lastName}
            email={user.email}
            collapsed={collapsed}
            logout={logout}
          />
        ) : (
          <div style={{ margin: '0 auto' }}>
            <LoadingOutlined style={{ fontSize: 16 }} spin />
          </div>
        )}
      </Footer>
    </Content>
  );
};

export default SideBar;
