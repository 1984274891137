import React, { useEffect, useState } from 'react';
import { TitledVerticalFlexLayout } from '../../features/shared/components/VerticalFixedLayout/VerticalFixedLayout';
import { useLocationChange } from '../../features/analytics/hooks/useLocationChange';
import { Button, Cascader, Space } from 'antd';
import { CustomerIntelligenceProvider } from '../../features/insights/context/CustomerIntelligenceProvider';
import { useCustomerIntelligenceFilters } from '../../features/insights/hooks/useCustomerIntelligenceFilters';
import { Alert } from '../../components/alerts/Alert';
import { CategoriesCard } from './components/CategoriesCard';
import { SubCategoriesCard } from './components/SubCategoriesCard';
import { SmartClipsDrawer } from './components/SmartClipsDrawer';
import { useCustomerIntelligenceData } from '../../features/insights/hooks/useCustomerIntelligenceData';
import { ManageInsightsFolderModal } from '../../features/libraries/components/ManageInsightFoldersModal';
import { ApiOutlined, DatabaseOutlined } from '@ant-design/icons';
import { ManageCategoriesModal } from './components/ManageCategoriesModal';
import { CategoryManagementProvider } from '../../features/insights/context/CategoryManagementProvider';
import { InsightCategoriesProvider } from '../../features/insights/context/InsightCategoriesProvider';
import { useInsightsAnalytics } from '../../features/insights/hooks/useInsightsAnalytics';
import { useLocation } from 'react-router-dom';
import { UpgradeToPro } from '../../componentsV2/UpgradeToPro';
import { Filters } from './components/Filters';
import useFeatures from '../../hooks/useFeatures';

import Styles from './styles';
import { useCrmIntegration } from '../../features/crm-integration/hooks/use-crm-integration';
import { InsightsAskMeAnything } from './components/InsightsAskMeAnything';
const { Contents } = Styles;

const CATEGORIES = [
  {
    label: 'Risks',
    value: 'Risks',
  },
  {
    label: 'Product Feedback',
    value: 'Product Feedback',
  },
  { label: 'Growth Opportunities', value: 'Growth Opportunities' },
  {
    label: 'Advocacy',
    value: 'Advocacy',
  },
];

export const Insights: React.FC = () => {
  useLocationChange();

  const [showManageCategoriesModal, setShowManageCategoriesModal] = useState(false);

  const [oldDR, setOldDR] = React.useState<(null | Date)[]>([null, null]);

  const { category, dateRange, selectAccounts, changeCategory } = useCustomerIntelligenceFilters();
  const { workspaceCrmIntegration } = useCrmIntegration();
  const isCRMConnected = workspaceCrmIntegration?.workspace.integration !== null;

  const { meetingsCount } = useCustomerIntelligenceData();
  const { portfolio } = useInsightsAnalytics();

  const { search } = useLocation();

  const features = useFeatures();
  const availableFeature = features.portfolioInsights;

  const handleConnectCrm = () => {
    window.open('/settings?tab=integrations', '_blank');
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const type = params.get('type');
    const accountId = params.get('accountId');

    if (type) {
      changeCategory(type);
    }

    if (accountId) {
      const [id, name, provider] = accountId.split(',');
      selectAccounts([{ id, name, provider }]);
    }
  }, [search]);

  useEffect(() => {
    if (!oldDR[0] && !oldDR[1]) {
      setOldDR(dateRange);
    } else {
      portfolio.filtered('date range');
    }
  }, [dateRange]);

  return (
    <CustomerIntelligenceProvider>
      <InsightCategoriesProvider insightType={category}>
        <CategoryManagementProvider>
          <ManageCategoriesModal
            open={showManageCategoriesModal}
            onClose={() => setShowManageCategoriesModal(false)}
            onDiscard={() => setShowManageCategoriesModal(false)}
            onChangesSaved={() => null}
          />
          <TitledVerticalFlexLayout
            sider={true}
            title="VoC Topic Tracking"
            titleDescription={
              <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <div style={{ maxWidth: '1000px' }}>
                  This tool lets you analyze data exclusively from your external meetings, providing
                  deeper insights into client interactions.
                </div>

                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <Cascader
                    options={CATEGORIES}
                    changeOnSelect
                    defaultValue={[category || 'Risk']}
                    value={[category || 'Risk']}
                    multiple={false}
                    allowClear={false}
                    disabled={!availableFeature}
                    dropdownMenuColumnStyle={{
                      fontFamily: 'Cera Pro',
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '20px',
                      letterSpacing: '0em',
                      textAlign: 'left',
                    }}
                    onChange={c => {
                      changeCategory(CATEGORIES.filter(cat => cat.value === c[0])[0].value);
                      portfolio.filtered('category');
                    }}
                  />
                </div>
              </div>
            }
          >
            <UpgradeToPro plan="business" display={!availableFeature}>
              <Contents>
                <ManageInsightsFolderModal />
                <Filters />
                <SmartClipsDrawer />

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '50px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <InsightsAskMeAnything category={category!} />
                    {!isCRMConnected && (
                      <Button type="link" onClick={handleConnectCrm}>
                        <Space>
                          Connect your CRM <ApiOutlined />
                        </Space>
                      </Button>
                    )}
                  </div>

                  <Alert
                    customIcon={<DatabaseOutlined style={{ color: '#21A6FF' }} />}
                    message={`Insights come from ${meetingsCount} meeting recaps based on your applied filters.`}
                    variant="info"
                    title=""
                  />
                </div>

                <CategoriesCard onManageCategories={() => setShowManageCategoriesModal(true)} />

                <SubCategoriesCard />
              </Contents>
            </UpgradeToPro>
          </TitledVerticalFlexLayout>
        </CategoryManagementProvider>
      </InsightCategoriesProvider>
    </CustomerIntelligenceProvider>
  );
};

// const createFilter = (isNew: boolean = false) => {
//   setNewFilter({
//     id: isNew ? '' : selectedFilter?.id || '',
//     name: isNew ? '' : selectedFilter?.name || '',
//     config: {
//       // accounts: accounts.map(a => JSON.stringify(a)),
//       // industry: industries.filter(i => i.selected).map(i => i.id),
//       // lifecycleStage: lifecycleStages.filter(l => l.selected).map(l => l.id),
//     },
//   });

//   if (isNew) {
//     setShowFiltersModal(true);
//   }
// };
//
// const handleSaveFilter = (filter: StoredFilterType) => {
//   handleShowModal(false);
//   setSelectedFilter(filter);
// };
//
// const handleShowModal = (value: boolean) => {
//   setShowFiltersModal(value);
//   setNewFilter(undefined);
// };
//
// const handleRemoveFilter = (filter: StoredFilterType) => {
//   if (selectedFilter && selectedFilter.id === filter.id) {
//     setSelectedFilter(undefined);
//   }
// };

/*
                      <SelectAll
                      style={{ minWidth: '120px' }}
                      label="Industry"
                      onSelectionChanged={(values: string[]) => {
                        toggleIndustries(values);
                        portfolio.filtered('industry');
                        }}
                        showAll
                        options={[...industries.map(ind => ({ label: ind.id, id: ind.id }))]}
                        value={industries.filter(l => l.selected).map(l => l.id)}
                        />
                        
                        <SelectAll
                        style={{ minWidth: '200px' }}
                        label="Lifecycle Stage"
                        onSelectionChanged={(values: string[]) => {
                          toggleLifecycleStages(values);
                          portfolio.filtered('maturity level');
                          }}
                          showAll
                          options={[...lifecycleStages.map(ind => ({ label: ind.id, id: ind.id }))]}
                          value={lifecycleStages.filter(l => l.selected).map(l => l.id)}
                          />
                          */

// useEffect(() => {
//   if (selectedFilter) {
//     toggleIndustries(selectedFilter.config?.industry || []);
//     toggleLifecycleStages(selectedFilter.config?.lifecycleStage || []);
//     selectAccounts(
//       (selectedFilter.config?.accounts?.map(a => JSON.parse(a)) as {
//         id: string;
//         provider: string;
//         name: string;
//       }[]) || [],
//     );
//   }
// }, [selectedFilter]);

/* 
                      <Dropdown.Button
                      style={{ width: 'auto' }}
                      type="primary"
                      menu={{
                        items: selectedFilter
                        ? [
                          {
                            key: '1',
                            label: 'Save as New Filter',
                            onClick: () => createFilter(true),
                            },
                            ]
                            : [],
                            }}
                            onClick={() => createFilter(!!!selectedFilter)}
                            >
                            {selectedFilter ? 'Save changes' : 'Save as new Filter'}
                            </Dropdown.Button> */
